import React, { useEffect, useState } from "react";

import Emitter from "../helpers/eventManager";

export default function Modal() {
  
  const [showModal, setShowModal] = useState();
  const [headline, setHeadline] = useState();
  const [body, setBody] = useState();
  const [data, setData] = useState();
  const [showCancel, setShowCancel] = useState();
  const [type, setType] = useState();
  const [primaryActionText, setPrimaryActionText] = useState();

  function confirm() {
    setShowModal(false);
    Emitter.emit('MODAL_ACTION', data)
  }

  function getButtonStyle(type) {
    if(type === 'danger') {
      return 'bg-red-600 hover:bg-red-400 active:bg-red-800 border-red-700 hover:border-red-500';
    } else if(type === 'standard') {
      return 'bg-blue-600 hover:bg-blue-400 active:bg-blue-800 border-blue-700 hover:border-blue-500';
    }
  }


  useEffect(() => {   
    Emitter.on('MODAL_EVENT', (e) => {
      setHeadline(e.headline);
      setBody(e.body);
      setData(e.data);
      setShowModal(e.showModal);
      setShowCancel(e.showCancel);  
      setType(e.type);
      setPrimaryActionText(e.primaryActionText);
    })

    return () => Emitter.off('MODAL_EVENT');    
  }, [])

  return (
    <> 
      {showModal ? (
        <>
          <div
            className="font-open-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto m-6 mx-auto max-w-lg">            
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-2">
                  <h3 className="text-2xl font-bold text-align-center pt-5">
                    {headline}
                  </h3>
                <div className="relative p-4 pt-0 flex-auto">
                  <p className="mb-5 mt-3 text-md leading-relaxed">
                    {body}
                  </p>
                  <div className="flex justify-end">  
                  <button className={`${getButtonStyle(type)} text-white font-bold py-1 px-4 border-b-4 rounded`} onClick={() => confirm()}>{primaryActionText}</button>
                  {
                    showCancel ? (
                      <button className='ml-3 text-slate-800 hover:text-slate-600 font-bold py-1 px-4 border-b-4 border border-slate-700 hover:border-slate-500 active:bg-slate-100 active:border-slate-800 rounded' onClick={() => setShowModal(false)}>Cancel</button>  
                    ) : null 
                  }                                    
                  </div>
                  
                </div>                
                
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}