import { useParams } from 'react-router-dom';

function MobileRedirect() {  

    const { uuid } = useParams();

    function copyToClipboard() {
        navigator.clipboard.writeText(`${window.location.origin}/${uuid}`);
    }
  
    return (
        <div className="overflow-scroll">   
            <div className="pt-10 p-2 m-auto mt-0 max-w-xl">
                <h1 className="pb-8 text-2xl font-bold">Sorry, you can only use Guess Who Generator on a bigger screen</h1>
                <h2>We've got your photos though! You can use the following link and continue on a desktop PC or iPad.</h2>
                
                <div className='mt-10 m-2 bg-gray-400 pt-10 p-1 pb-10 shadow-md'>
                    <a className='text-md block font-bold text-white underline' href={`${window.location.origin}/${uuid}`}>{window.location.host}/{uuid}</a>
                    <button onClick={copyToClipboard} className='block m-auto bg-blue-600 mt-5 hover:bg-blue-400 active:bg-blue-800 border-blue-700 hover:border-blue-500 text-white font-bold py-1 px-4 border-b-4 rounded'>Copy to Clipboard</button>
                </div>
                
            </div>
        </div>        
    );
}

export default MobileRedirect;