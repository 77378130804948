function About() {  
  
    return (
        <div className="overflow-scroll">   
            <div className="pt-20 p-4 m-auto mt-0 max-w-xl">
                <h1 className="pb-16 text-4xl font-bold">FAQs</h1>
                <h2 className="text-left text-2xl font-bold">What is 'Guess Who Generator?'</h2>
                <p className="text-left pb-10">Guess Who Generator allows you to make personalised playing cards for your Guess Who board game. You can upload photos of friends and family, crop their faces and print out compatible playing cards.</p>
                <h2 className="text-left text-2xl font-bold">Are the cards compatible with my version of Guess Who?</h2>
                <p className="text-left pb-5">This site can generate playing cards that have the dimensions 30mm x 35mm. The packaging will look similar to the below photo:</p>
                <img className="pb-5" src="game-box.png" alt="game box" />
                <h2 className="text-left text-2xl font-bold">What if I have a different version?</h2>
                <p className="text-left pb-10">If there's interest, I can add additional versions. Email me: ben@ben-holm.es</p>
                <h2 className="text-left text-2xl font-bold">What happens to the photos I upload?</h2>
                <p className="text-left pb-10">The photos are stored for 1 week on our server allowing you time to come back, reprint or edit, after which they're automatically deleted.</p>
                <h2 className="text-left text-2xl font-bold">How do I get started?</h2>
                <p className="text-left pb-20">Begin by uploading photos on the <a className="underline" href="/">homepage</a>. You can always add more photos later if you don't include everyone.</p>
            </div>
        </div>        
    );
}

export default About;