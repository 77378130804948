import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Home from './pages/home';
import Crop from './pages/crop';
import About from './pages/about';
import './App.css';
import Toast from './components/toast';
import Modal from './components/modal';
import MobileRedirect from './pages/mobile-redirect';



function App() {  

  const navigate = useNavigate();

  function navigateHome() {
    navigate('/');
  }

  if(useLocation().pathname === '/') {
    return (<Home></Home>);
  } else {
    return (
      <div className="App grid grid-rows-[auto_1fr] font-open-sans text-center h-screen">
        <header className="py-5 p-10 header-gradient">
          <h1 onClick={navigateHome} className='w-max cursor-pointer text-gray-50 font-bold text-left'>Guess Who Generator</h1> 
        </header>
        <main className='bg-slate-100 overflow-hidden grid'>
          <Routes>              
              <Route path="/about" element={<About />} />
              <Route path="/:uuid" element={<Crop />} />
              <Route path="/mobile/:uuid" element={<MobileRedirect />} />
          </Routes>
        </main>
        <Toast></Toast>
        <Modal></Modal>
      </div>
    );
  }  
}

export default App;

