import React from "react";

export default function Coffee(props) {
  const [showModal, setShowModal] = React.useState(false);


  props.childRef.current = {
    setShowModal
  }

  function shouldClose(e) {
    if(e.target.id === 'modal-background') {
      setShowModal(false);
    }
  }


  return (
    <> 
      {showModal ? (
        <>
          <div
            onClick={(e) => shouldClose(e)}
            id="modal-background"
            className="font-open-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">            
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <h3 className="text-2xl font-bold text-align-center pt-5">
                    Have fun!
                  </h3>                  
                {/*body*/}
                <div className="relative p-4 pt-0 flex-auto">
                  <p className="mb-5 mt-3 text-md leading-relaxed">
                    If you found this tool useful please consider buying me a coffee! 😀
                  </p>
                  <div className="flex justify-center pt-2">
                  <a href="https://www.buymeacoffee.com/wassupben" rel="noreferrer" target="_blank"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" className="h-10 center" /></a>
                  </div>
                  
                </div>                
                
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}