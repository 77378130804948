import axios from 'axios';

const url = process.env.REACT_APP_BASEURL || "http://localhost:3001";

export async function get(path) {
    const { data } = await axios.get(url + path);
    return data;

}

export async function post(path, body) {
    const { data } = await axios.post(url + path, body);
    return data;

}

export async function deleteImage(path) {
    const { data } = await axios.delete(url + path);
    return data;
}