import React, { useEffect, useState } from "react";

import Emitter from "../helpers/eventManager";

export default function Toast() {

    const [message, setMessage] = useState('');
    const [visibility, setVisibility] = useState('hidden');


    useEffect(() => {           
        Emitter.on('TOAST_EVENT', (e) => {
          setMessage(e.message);
          setVisibility('[animation:_fadeInFromTop_0.4s,_fadeOutToTop_0.4s_5s_forwards;]');
          setTimeout(() => {
            setVisibility('hidden');
          }, 6000)
        })
    
        return () => Emitter.off('TOAST_EVENT');
      }, [])
    
    return (
        <div className={`${visibility} absolute left-0 right-0 m-auto top-5 flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow`} role="alert">
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg">
                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
                </svg>
                <span className="sr-only">Error icon</span>
            </div>
            <div className="ml-3 text-sm font-normal">{message}</div>
        </div>
    )
}
