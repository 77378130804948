import Emitter from "../helpers/eventManager";

export default function PlayerCard({face}) {

  function deleteImage() {
    Emitter.emit('MODAL_EVENT', {
        headline: 'Do you want to delete this card?',
        body: `You can still use the original photo again to create another photo.`,
        showModal: true,
        data: {
          imageUrl: face.dataURL,
          imageType: 'cropped',
          action: 'DELETE'
        },
        showCancel: true,
        type: 'danger',
        primaryActionText: 'Delete'
    });
}

  return (
    <div key={face.faceNumber} className='guess-who-card m-[10px] shadow-md relative rounded'>
      <label className={`${face.name ? 'visible': 'hidden'} flex flex-col items-center px-1.5 py-1.5 z-20 absolute top-[-8px] right-[-8px] bg-slate-800 text-white rounded-full shadow tracking-wide  cursor-pointer hover:bg-slate-600 hover:text-white`}>
                        <svg className="w-4 h-4" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">       
                             <path d="M4.91666667,14.8888889 C4.91666667,15.3571429 5.60416667,16 6.0625,16 L12.9375,16 C13.3958333,16 14.0833333,15.3571429 14.0833333,14.8888889 L14.0833333,6 L4.91666667,6 L4.91666667,14.8888889 L4.91666667,14.8888889 L4.91666667,14.8888889 Z M15,3.46500003 L12.5555556,3.46500003 L11.3333333,2 L7.66666667,2 L6.44444444,3.46500003 L4,3.46500003 L4,4.93000007 L15,4.93000007 L15,3.46500003 L15,3.46500003 L15,3.46500003 Z" ></path>
                        </svg>                    
                        <button onClick={deleteImage} className="hidden" />
                    </label>
                    <img src={face.dataURL} alt={face.name}/>      
        <div className='guess-who-card-name overflow-hidden text-zinc-600 text-sm font-normal'>
            {face.name}
        </div>
    </div>
  );
}