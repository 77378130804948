import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Emitter from "../helpers/eventManager";


import { useParams } from 'react-router-dom';
import { get, post, deleteImage } from '../helpers/httpRequest';

import { upload } from '../helpers/fileHelper';

import PlayerCard from '../components/playerCard';
import Coffee from '../components/coffee';
import Resizer from '../components/resizer';
import RawImage from '../components/rawImage';


 function Crop() {  

    const navigate = useNavigate();

    const { uuid } = useParams();
    const [facesArray, setFacesArray] =  useState([]);

    const previewCanvasRef = useRef(null);
    const coffeeRef = useRef(null);
    const cropRef = useRef(null);
    const rawImagesScrollWindow = useRef(null);    

    const [cropError, setCropError] = useState('');

    const [rawImages, setRawImages] = useState([]);
    
    const [activePhoto, setActivePhoto] = useState();
    const activePhotoRef = useRef(activePhoto);

    function setActivePhotoWrapper(photo) {
        activePhotoRef.current = photo;
        setActivePhoto(photo);
    }

    const [personName, setPersonName] = useState('');

    async function handleFileInput(e) {
        try {
            await upload(e.target.files, uuid, 'raw');
            const {rawImages} = await get(`/images/${uuid}`);
            setRawImages(rawImages);
            setTimeout(() => {
                scrollToBottomOfRawImages();
            }, 400);
        } catch(e) {
            if(e.message) {
                Emitter.emit('TOAST_EVENT', {message: e.message})
            } else {
                Emitter.emit('TOAST_EVENT', {message: "Something went wrong! Please try again later"});
            }
            console.error(e);
        }
    }

    function handleClick(e) {
        e.target.value = null;
    }

    function scrollToBottomOfRawImages() {
        rawImagesScrollWindow.current.lastElementChild.scrollIntoView({ behavior: "smooth" });
    }

    function utf8ToHex(str) {
        return Array.from(str).map(c => 
          c.charCodeAt(0) < 128
            ? c.charCodeAt(0).toString(16)
            // eslint-disable-next-line no-useless-escape
            : encodeURIComponent(c).replace(/\%/g,'').toLowerCase()
        ).join('');
      }

    function hexToUtf8(hex) {
    return decodeURIComponent('%' + hex.match(/.{1,2}/g).join('%'));
    }
    
    useEffect(() => {    
        const isMobile = () => /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        const makeRequest = async () => {
            try {
                const response = await get(`/images/${uuid}`);

                if(isMobile()) {
                    navigate(`/mobile/${uuid}`);
                }

                setRawImages(response.rawImages);
                setActivePhotoWrapper(response.rawImages[0])  
                setFacesArray(setupFaceArray(response.croppedImages));
            } catch(e) {
                console.log(e);
                if(e.response && e.response.data && e.response.data.error) {
                    Emitter.emit('MODAL_EVENT', {
                        headline: "No photos to see here...",
                        body: "This might be because the link is invalid or the images have been deleted. All images are deleted from our server after 1 week. You can upload new images from the homepage",
                        showModal: true,
                        showCancel: false,
                        type: 'standard',
                        primaryActionText: 'Go to homepage',
                        data: {
                            action: 'NAVIGATE_HOME'
                        }
                    })
                }
            }
            
        }
        
        makeRequest();         
        
        Emitter.on('MODAL_ACTION', async (e) => {
            console.log(e);
            if(e.action === 'DELETE') {            
                const urlPath = e.imageUrl.split('/').splice(4, 6).join('/');
                await deleteImage(`/image/${urlPath}`);

                if(e.imageType === 'raw') {
                    const {rawImages} = await get(`/images/${uuid}`);
                    setRawImages(rawImages);
                    if(e.imageUrl === activePhotoRef.current) {
                        setActivePhotoWrapper(rawImages[0]);
                    }
                } else if(e.imageType === 'cropped') {                        
                    const { croppedImages } = await get(`/images/${uuid}`);
                    setFacesArray(setupFaceArray(croppedImages));
                }                
            } else if(e.action === 'NAVIGATE_HOME') {
                navigate(`/`);
            }
        })
    
        return () => Emitter.off('MODAL_RAW_IMAGE_ACTION');  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function setupFaceArray(respondFaces = []) {
        let faces = [];
        for(let i = 0; i < 24; i++) {
            let face = {
                faceNumber: i
            }
            if(respondFaces[i]) {
                face.name = hexToUtf8(respondFaces[i].split('/').pop().split('.').shift());
                face.dataURL = respondFaces[i];
            } else {
                face.dataURL = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
            }
            faces.push(face);
        }
        return faces;
    }


    function addFace(newFace) {
        if(getCompletedFacesCount() === 24) {
            setCropError('You have a full set. Either download the cards or remove a piece to add another.')
            return;
        }
        if(cropRef.current.crop === null) {
            setCropError(`You must crop someone from the photo.`);
            return;
        }
        if(!newFace || newFace === '') {
            setCropError(`You must name the person you have cropped.`);
            return;
        }  
        if(newFace.length > 10) {
            setCropError(`The name must be less than 10 characters.`);
            return;
        }      
        setCropError('');
        let facesArrayCopy = [...facesArray];
        for(let face of facesArrayCopy) {
            if(!face.name) {
                face.name = newFace;
                face.dataURL = previewCanvasRef.current.toDataURL();
                break;
            }
        }        
        
        previewCanvasRef.current.toBlob((blob)  => {
            const formData = new FormData();
            formData.append("files[]", blob, `${utf8ToHex(newFace)}`);
            post(`/images/${uuid}?imageType=cropped`, formData).then( async() => {
                const { croppedImages } = await get(`/images/${uuid}`);
                setFacesArray(setupFaceArray(croppedImages));
            });
        });            

        setFacesArray(facesArrayCopy);
        setPersonName('');
        resetCrop();
    }

    function resetCrop() {
        cropRef.current.setCrop(null);
    }

    function getCompletedFacesCount() {
        let count = 0;
        for(let face of facesArray) {
            if(face.name) {
                count++;
            }
        }
        return count;
    }

    function rawImageSelected(imageUrl) {
        setActivePhotoWrapper(imageUrl);
        resetCrop();
        setCropError('');
    }

    async function downloadPDF() {
        if(getCompletedFacesCount() > 0) {
            window.open((process.env.REACT_APP_BASEURL || "http://localhost:3001") + `/pdf/${uuid}`);
            coffeeRef.current.setShowModal(true);
        } else {
            Emitter.emit('TOAST_EVENT', {message: "You must add at least 1 photo before downloading."});
        }
    }    
    
    return (            
        <div className='grid grid-rows-[1fr_auto] overflow-hidden'>
            <div className='grid grid-cols-[1fr_1fr_1fr] grid-rows-[auto_minmax(0,_1fr)] overflow-hidden px-10 max-w-8xl mx-auto'>            
                <div className='py-10 col-span-3 center'>
                    <h1 className='text-3xl font-open-sans font-bold mt-5'>Create cards for all the faces</h1>
                    <p className='mt-3'><b>Select an image</b> from the left hand list, <b>crop their face</b> in the centre and <b>add their name</b>.</p>
                    <p className='mt-1'>You can see your current playing pieces in the right column.</p>                
                </div>      
                <div className='grid grid-rows-[minmax(0, 1fr)] relative'>
                <div className='overflow-y-scroll bg-slate-200 shadow-inner mr-10 mb-5' ref={rawImagesScrollWindow}>                                
                    <label className="flex flex-col items-center px-2 py-2 z-20 absolute top-[-10px] left-[-10px] bg-blue-600 text-white rounded-full shadow-lg tracking-wide  cursor-pointer hover:bg-blue-400 hover:text-white">
                        <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                        </svg>                    
                        <input type='file' accept="image/png, image/jpeg" onClick={handleClick} onChange={handleFileInput} className="hidden" />
                    </label>
                         
                            {
                                rawImages.map((imageUrl) => {
                                    return (
                                        <RawImage key={imageUrl} imageUrl={imageUrl} rawImageSelected={rawImageSelected}></RawImage>
                                    )                                    
                                })
                            }                
                                                
                    </div>
                    </div>                          
                    <div className='grid grid-rows-3 mb-5 overflow-hidden grid-rows-[auto_60px_50px] max-h-[700px]'>

                        <Resizer cropRef={cropRef} previewCanvasRef={previewCanvasRef} activePhoto={activePhoto}></Resizer>
                    
                        <div className='flex flex-wrap justify-center items-center'>                        
                            <label htmlFor="person_name" className="block text-md text-gray-900">Name:</label>
                            <input value={personName}  onChange={e => setPersonName(e.target.value)}  type="text" id="person_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block max-w-sm p-2 mx-3" placeholder="John" required></input>                    
                            <button className='bg-blue-600 hover:bg-blue-400 active:bg-blue-800 text-white font-bold py-1 px-4 border-b-4 border-blue-700 hover:border-blue-500    rounded' onClick={() => addFace(personName)}>Add</button>                            
                        </div>                        
                        <p className='text-red-400'>{cropError}</p>                        
                    </div>
                    <div className='guess-who-cards-container overflow-y-scroll bg-slate-200 ml-10 mb-5 shadow-inner'>
                        <div className='flex justify-center flex-wrap'>
                            {
                                facesArray.map((face) => {
                                    return (
                                        <PlayerCard key={face.faceNumber} face={face}></PlayerCard>
                                    )
                                })
                            }                       
                            
                        </div>
                    </div>                    
                
            </div>
            <div className='header-gradient-inverse flex justify-between p-4  '>
                <div className='flex items-center'>
                <p className='text-gray-50'>Hit download when you're ready to generate the cards</p>
                </div>    
                <div className='flex items-center'>
                    <p className='text-gray-50 font-bold mr-2'>{getCompletedFacesCount() + '/24 cards completed'}</p>
                <button onClick={() => downloadPDF()} className="bg-blue-600 hover:bg-blue-400 active:bg-blue-800 text-white font-bold py-1 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded inline-flex items-center">
                    <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                    <span>Download</span>
                </button>
                    </div>                                                
            </div>
            <Coffee childRef={coffeeRef}></Coffee>            
        </div>
        
    );
}

export default Crop;