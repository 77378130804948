import React, { useState } from 'react';
import {upload} from '../helpers/fileHelper'
import { useNavigate } from "react-router-dom";

function Home() {  

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')

  function handleFileInput(e) {
      uploadFiles(e.target.files);
  }

  function handleDrag(e) {
    e.preventDefault();
  };

  function handleDrop(e) {
      e.preventDefault();
      console.log(e);
      const droppedFiles = Array.from(e.dataTransfer.files)
      uploadFiles(droppedFiles);
  };

  async function uploadFiles(files) {      
      try {
        setIsLoading(true);
        const uuid = await upload(files);
        navigate(`/${uuid}`);
      } catch(e) {
        setIsLoading(false);
        console.log(e);
        if(e.response && e.response.data && e.response.data.error) {
          setErrorMessage(e.response.data.error);
        } else {
          setErrorMessage('Oh no, something went wrong! Please try again or try different photos.')
        }        
      }
      
  }

  
    return (
      <main className="App header-gradient font-open-sans lg:h-screen lg:overflow-hidden">
        <header className='flex justify-end p-5 max-w-screen-2xl'><a className='underline text-white font-bold' href="/about">Learn More</a></header>
        <div className="p-4 lg:p-36 lg:pb-0 text-gray-50 m-auto h-full max-w-screen-2xl flex lg:flex-row flex-col">
          <div className=" p-10 pt-0 lg:[width:_50%]">
            <h1 className=' font-bold text-5xl lg:text-6xl xl:text-8xl text-left mb-6 '>Guess Who Generator</h1>  
            <p className=" font-bold text-xl">Create personalised Guess Who playing cards of your friends and family!</p>                        
                {
                  isLoading ? (                                        
                       <label htmlFor="dropzone-file" className="cursor-not-allowed mt-10 bg-[rgba(0,0,0,0.1)] hover:bg-[rgba(0,0,0,0.2)] flex flex-col items-center justify-center border-2 border-gray-100 border-dashed rounded-lg cursor-pointer ">
                       <div className="flex flex-col items-center justify-center pt-5 pb-6">
                         <div className='pb-3'>
                           <svg className="animate-spin -ml-1 mr-3 h-7 w-7 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                             <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                             <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                           </svg>
                         </div> 
                         <p className="mb-2 text-sm"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                             <p className="text-xs">PNG, JPG (MAX. 8MB)</p>
                         </div>
                       <input id="dropzone-file" disabled accept="image/png, image/jpeg" type="file" multiple onChange={handleFileInput} className="hidden" />
                     </label>       
                  ) : (
                    <>
                    <label htmlFor="dropzone-file" onDragOver={handleDrag} onDragLeave={handleDrag}  onDrop={handleDrop} className=" mt-10 bg-[rgba(0,0,0,0.1)] hover:bg-[rgba(0,0,0,0.2)] flex flex-col items-center justify-center border-2 border-gray-100 border-dashed rounded-lg cursor-pointer ">
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <div className='pb-3'>
                        <svg className="w-8 h-8" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                        </svg>
                        </div> 
                        <p className="mb-2 text-sm"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                            <p className="text-xs">PNG, JPG (Max. 8MB each)</p>
                        </div>
                      <input id="dropzone-file" accept="image/png, image/jpeg" type="file" multiple onChange={handleFileInput} className="hidden" />
                    </label>                                      
                    <div className='pt-2 text-black'>{errorMessage}</div>
                    </>
                    
                  )
                }
                                                    
          </div>
          <div className=' p-10 pt-0 lg:[width:_50%]'>
            <div className="flex justify-between">
              <div className="[animation:_fadeIn_1.5s_0s_forwards;] [width:_30%] [transform:_translateY(25%);] [opacity:_0;]">
                <div className='shadow-md [background:_linear-gradient(90deg,_rgba(223,53,53,1)_0%,_rgba(255,0,0,1)_100%)] relative [padding:_5%] rounded origin-top'>      
                  <img alt="" src="./ben.jpeg" className=''/>
                  <div className='text-center bg-white [padding:_3%] [margin-top:_5%] overflow-hidden text-zinc-600 text-sm font-normal'>
                    Ben
                  </div>
                </div>
              </div>          
              <div className="[animation:_fadeIn_1.5s_1s_forwards;] [width:_30%] [transform:_translateY(25%);] [opacity:_0;]">
                <div className='shadow-md [background:_linear-gradient(90deg,_rgba(223,53,53,1)_0%,_rgba(255,0,0,1)_100%)] [padding:_5%] relative rounded origin-top'>      
                  <img alt="" src="./jessica.jpeg"/>
                  <div className='text-center bg-white [padding:_3%] [margin-top:_5%] overflow-hidden text-zinc-600 text-sm font-normal'>
                    Jessica
                  </div>
                </div>
              </div>            
              <div className="[animation:_fadeIn_1.5s_2s_forwards;] [width:_30%] [transform:_translateY(25%);] [opacity:_0;]">
                <div className='shadow-md [background:_linear-gradient(90deg,_rgba(223,53,53,1)_0%,_rgba(255,0,0,1)_100%)] [padding:_5%] relative rounded origin-top'>      
                  <img alt="" src="./kelvin.jpeg"/>
                  <div className='text-center bg-white [padding:_3%] [margin-top:_5%] overflow-hidden text-zinc-600 text-sm font-normal'>
                    Kelvin
                  </div>
                </div>
              </div>            
            </div>    
            <div className="pt-10">
              <ul className="list-inside font-bold list-disc text-lg overflow-hidden">
                <li className="p-5 mb-4 bg-gray-100 text-black">Upload your photos 📸</li>
                <li className="p-5 mb-4 bg-gray-100 text-black">Crop faces ✂️</li>
                <li className="p-5 mb-4 bg-gray-100 text-black">Download, print and cut out 🖨️</li>
                <li className="p-5 mb-4 bg-gray-100 text-black">Have fun! 😀</li>
              </ul>
            </div>
          </div>
                
        </div>                        
      </main>
    );
}

export default Home;