import { post } from './httpRequest';

export async function upload(files, uuid = null, imageType = null) {      
      const formData = new FormData();
      formData.append("files[]", files);
      for(let file of files) {
          formData.append("files[]", file);
          console.log(file.size);
          // 4mb max size
          if(file.size > 8100000) {
            throw new Error('Images must be smaller than 8MB.')
          }
      }
      if(!uuid) {
        const response = await post('/images/upload', formData);
        return response.directoryUUID;
      } else {
        await post(`/images/${uuid}/?imageType=${imageType}`, formData);
      }
      
}
