import React, { useState, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop'

import { canvasPreview } from '../helpers/canvasPreview'

import 'react-image-crop/dist/ReactCrop.css'

export default function Resizer({activePhoto, previewCanvasRef, cropRef}) {

    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();    

    const [imageHeight, setImageHeight] = useState('');   
    const [imageWidth, setImageWidth] = useState('');    

    const imgRef = useRef(null);

    const containerRef = useRef(null);

    cropRef.current = {
        crop,
        setCrop
    }


    if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0,
        )
      }
      // adapted from: https://www.npmjs.com/package/intrinsic-scale
    function getObjectFitSize(contains /* true = contain, false = cover */, containerWidth, containerHeight, width, height){
        var doRatio = width / height;
        var cRatio = containerWidth / containerHeight;
        var targetWidth = 0;
        var targetHeight = 0;
        var test = contains ? (doRatio > cRatio) : (doRatio < cRatio);

        if (test) {
            targetWidth = containerWidth;
            targetHeight = targetWidth / doRatio;
        } else {
            targetHeight = containerHeight;
            targetWidth = targetHeight * doRatio;
        }

        return {
            width: targetWidth,
            height: targetHeight,
            x: (containerWidth - targetWidth) / 2,
            y: (containerHeight - targetHeight) / 2
        };
    }

    function setImageSize() {
        setCrop(null);
        if(!imgRef.current.height) {
            return;
        }        
        const imageDimensions = getObjectFitSize(true, containerRef.current.clientWidth - 40, containerRef.current.clientHeight - 40, imgRef.current.naturalWidth, imgRef.current.naturalHeight);
        setImageHeight(imageDimensions.height + 'px');
        setImageWidth(imageDimensions.width + 'px');        
    }
        
        useEffect(() => {
            window.addEventListener('resize', setImageSize)

            return () => window.removeEventListener('resize', setImageSize)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

      // eslint-disable-next-line react-hooks/exhaustive-deps
      useEffect(() => setImageSize(), [activePhoto])



    return (
      <>
        <div className='image-select-background shadow-inner p-5 h-full min-h-full max-h-full overflow-y flex items-center justify-center' ref={containerRef}>          
            <div>
                <ReactCrop aspect={25/21} crop={crop} onChange={c => setCrop(c)} onComplete={(c) => setCompletedCrop(c)}>
                    <img style={{height: imageHeight, width: imageWidth}} onLoad={() => setImageSize()} crossOrigin="anonymous" ref={imgRef} src={activePhoto} alt="" />
                </ReactCrop>
            </div>            
        </div>
                                
            <canvas
            ref={previewCanvasRef}
            className='hidden'
            />
      </>
    );
  }